import { render, staticRenderFns } from "./CookiePolicy.vue?vue&type=template&id=6838f70c&scoped=true"
import script from "./CookiePolicy.vue?vue&type=script&lang=js"
export * from "./CookiePolicy.vue?vue&type=script&lang=js"
import style0 from "./CookiePolicy.vue?vue&type=style&index=0&id=6838f70c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6838f70c",
  null
  
)

export default component.exports