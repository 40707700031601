var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home left-column-wraper d-flex flex-column"},[_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-list-item',{staticClass:"section-nav-link",attrs:{"to":{
				name: 'section',
				params: { id: 'absorption1' }
			},"active-class":"active"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase subtitle-1 font-weight-medium"},[_vm._v(_vm._s(_vm.mainNavigation.name))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right-box-outline")])],1)],1),_c('v-divider')],1),_c('div',{staticClass:"flex-grow-1 column-panels"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-expansion-panels',{attrs:{"accordion":"","transparent":"","multiple":_vm.allpanels},model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}},_vm._l((_vm.mainNavigation.childs),function(item){return _c('v-expansion-panel',{key:item.key},[_c('v-expansion-panel-header',{staticClass:"body-2 text-uppercase"},[_vm._v(_vm._s(item.name))]),_c('v-expansion-panel-content',{staticClass:"body-3"},[_c('div',{domProps:{"innerHTML":_vm._s(item.cidescription)}}),_c('v-btn',{staticClass:"mt-3",attrs:{"small":"","outlined":"","color":"primary","to":{
								name: 'section',
								params: { id: item[_vm.paramkey] }
							}}},[_vm._v(" "+_vm._s(_vm.$t('section_start'))+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)}),1)],1)],1),_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-divider'),_vm._l((_vm.micrositeNavigation.childs),function(item,ind){return [_c('v-list-item',{key:item.cid,staticClass:"section-nav-link",attrs:{"to":{
					name: item.route,
					params: { id: item[_vm.paramkey] }
				},"active-class":"active"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase subtitle-1 font-weight-medium"},[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right-box-outline")])],1)],1),_c('v-divider',{key:ind})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }