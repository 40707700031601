<template>
	<div class="fill-height">
		<News v-if="$route.params.id == 'news'"></News>
		<Resources v-if="$route.params.id == 'resources'"></Resources>
		<Tables v-if="$route.params.id == 'tables'"></Tables>
		<LegalNotice v-if="$route.params.id == 'legal-notice'"></LegalNotice>
		<TermsOfUse v-if="$route.params.id == 'terms-of-use'"></TermsOfUse>
		<PrivacyPolicy
			v-if="$route.params.id == 'privacy-policy'"
		></PrivacyPolicy>
	</div>
</template>

<script>
import News from '../components/News'
import Resources from '../components/Resources'
import Tables from '../components/Tables'
import LegalNotice from '../components/LegalNotice'
import PrivacyPolicy from '../components/PrivacyPolicy'
import TermsOfUse from '../components/TermsOfUse'
export default {
	components: {
		News,
		Resources,
		Tables,
		LegalNotice,
		PrivacyPolicy,
		TermsOfUse
	}
}
</script>

<style lang="scss" scoped>
.fill-height {
	max-height: calc(100% - 85px);
}
</style>
