<template>
	<div class="fill-height animation-section" id="animID">
		<!-- v-hammer:swipe.horizontal="onSwipe" -->
		<div id="loading-wrapper_anim" v-if="!animloaded">
			<div id="loading-text">LOADING</div>
			<div id="loading-content"></div>
		</div>

		<Minimap></Minimap>
		<UtilityToolbar></UtilityToolbar>
		<AnimationPlayerToolbar
			v-if="isSection"
			@prev-animation="callPrevAnimation"
			@next-animation="callNextAnimation"
			@seek="seek"
			:playprogress="playprogress"
			:stepsprogress="stepsprogress"
		></AnimationPlayerToolbar>
		<HomeAnimationPlayerToolbar
			v-if="isIMLanding"
		></HomeAnimationPlayerToolbar>
		<AnimationSubtitles v-if="isSection" />
		<v-row justify="center" align="center">
			<!-- Here is injected the animation player... -->
		</v-row>
		<div class="animID2 fill-height animation-section" id="animID2"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import Minimap from './Minimap'
import UtilityToolbar from './UtilityToolbar'
import AnimationPlayerToolbar from './AnimationPlayerToolbar'
import AnimationSubtitles from './AnimationSubtitles'
import HomeAnimationPlayerToolbar from './HomeAnimationPlayerToolbar'
import Hammer from 'hammerjs'

export default {
	name: 'AnimationPlayer',
	components: {
		AnimationPlayerToolbar,
		HomeAnimationPlayerToolbar,
		AnimationSubtitles,
		UtilityToolbar,
		Minimap
	},
	data: () => ({
		captureclass: 'animationcapture',
		animloaded: false,
		playprogress: 0,
		call_once: 0,
		loadingNextStep: false,
		nextanim: false,
		fromDL: true,
		pinch: false,
		stepsprogress: [],
		stepsPlaylist: [
			'absorption1',
			'absorption2',
			'transport1',
			'usage1',
			'usage2',
			'usage3',
			'usage4',
			'loss1',
			'recycling1',
			'storage1',
			'regulation2',
			'regulation3'
		]
	}),
	computed: {
		...mapState([
			'paramkey',
			'previewpanels',
			'showlabels',
			'selectedElement',
			'currentlang',
			'tourmode',
			'tourautoplay',
			'tourcurrentstep',
			'toursteptransitioning',
			'tourstepjump',
			'selectedElementName',
			'tourstepplaying',
			'fromURL',
			'sound',
			'animationplaying',
			'animationLoading',
			'animationinterrupted',
			'manualprogress',
			'sound_enabled',
			'savescr'
		]),
		...mapGetters(['location', 'isSection', 'isIMLanding'])
	},
	watch: {
		previewpanels(newValue) {
			window.iron3d.preview(newValue)
		},
		showlabels(newValue) {
			if (newValue) {
				window.iron3d.showLabels(true)
			} else {
				window.iron3d.showLabels(false)
			}
		},
		currentlang(newValue) {
			if (typeof window.iron3d != 'undefined') {
				window.iron3d.setLanguage(newValue)
			}
		},
		savescr(newValue) {
			let _this = this
			if (newValue == 1) {
				let canvas = this.$el.getElementsByTagName('canvas')[0]
				// console.log(canvas)
				window.iron3d.capture(img => {
					img.className = _this.captureclass
					canvas.parentElement.insertAdjacentElement(
						'beforebegin',
						img
					)
					_this.$store.commit('SET_SCR', 2)
				})
			} else if (newValue == 0) {
				let img = _this.$el.querySelectorAll(
					'.' + _this.captureclass
				)[0]
				img.parentNode.removeChild(img)
			}
		},
		sound(notMuted) {
			window.iron3d.setMute(!notMuted)
		},
		tourmode(newValue) {
			if (newValue) {
				let didenter = window.iron3d.setStepByStepMode(true)
				window.iron3d.showLabels(true)
				this.$store.commit('TOGGLE_TOUR_STEPTRANSITIONING', true)
				if (didenter) {
					window.iron3d.select(null)
					if (this.tourstepjump) {
						window.iron3d.jumpToStep(self.tourcurrentstep)
					} else {
						window.iron3d.playUntilStep(self.tourcurrentstep)
					}
				}
			} else {
				window.iron3d.setStepByStepMode(false)
				if (!this.showlabels) {
					window.iron3d.showLabels(false)
				}
			}
		},
		tourcurrentstep(newValue) {
			if (this.tourstepjump) {
				window.iron3d.jumpToStep(newValue)
			} else {
				this.$store.commit('TOGGLE_TOUR_STEPTRANSITIONING', true)
				window.iron3d.playUntilStep(newValue)
			}
		},
		tourstepjump(newValue) {
			if (newValue) {
				window.iron3d.jumpToStep(this.tourcurrentstep)
			}
		},
		animationplaying(playing) {
			if (window.iron3d) {
				if (playing) {
					window.iron3d.play()
				} else {
					window.iron3d.pause()
				}
			}
		},
		animationinterrupted(interrupted) {
			if (window.iron3d) {
				if (interrupted) {
					window.iron3d.interrupt()
				} else {
					window.iron3d.resume()
				}
			}
		},
		$route(to, from) {
			if (to.name != 'section') {
				this.$store.commit('TOGGLE_SOUND_ON', false)
				window.iron3d.navigate('navigation')
				this.$store.commit('EMPTY_PREVIEW')
				this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
			}
			if (to.name == 'section') {
				if (
					typeof to.params.id !== 'undefined' &&
					to.params.id != from.params.id
				) {
					window.iron3d.navigate(
						this.location.currentnode[this.paramkey]
					)
				} else {
					if (typeof to.params.element !== 'undefined') {
						window.iron3d.select(to.params.element)
					} else {
						window.iron3d.select(null)
					}
				}

				if (this.sound_enabled) {
					this.$store.commit('TOGGLE_SOUND_ON', true)
				} else {
					this.$store.commit('TOGGLE_SOUND_ON', false)
				}

				var this_ = this
				setTimeout(function() {
					//to section - no element selected
					if (typeof to.params.element == 'undefined') {
						if (to.params.id == from.params.id) {
							//console.log("same animation")
						} else {
							//console.log("different animation")
							this_.$store.commit('TOGGLE_STEP_PAUSE_', false)
							this_.$store.commit(
								'TOGGLE_TOUR_STEPPLAYING',
								false
							)

							/* this_.$gtag.pageview({
								page_title: this_.location.currentnode.name,
							}) */
							this_.$gtm.trackView(
								this_.location.currentnode.name,
								this_.$route.fullPath
							)

							// this_.$store.commit('TOGGLE_ANIMATIONLOADING', true)
							this_.$store.commit(
								'TOGGLE_TOUR_STEPTRANSITIONING',
								true
							)
						}
					}
					//to section element selected
					else {
						window.iron3d.select(to.params.element)
						/* this_.$gtag.pageview({
							page_title: this_.selectedElementName,
						}) */
						this_.$gtm.trackView(
							this_.selectedElementName,
							this_.$route.fullPath
						)
					}
				}, 3)
			}

			if (to.name == 'ironmetabolism') {
				this.$store.commit('TOGGLE_STEP_PAUSE_', false)
				this.$store.commit('TOGGLE_TOUR_STEPPLAYING', false)
				this.$store.commit('TOGGLE_ANIMATIONINTERRUPTED', false)
			}

			if (to.name == 'section') {
				// console.log("FROM:: "+from.name)
				if (from.name == 'ironmetabolism') {
					this.fromDL = false
					this.$store.commit('FROM_URL', false)
				}
			}
		}
	},
	methods: {
		...mapActions(['chageTourStep']),
		playAudio(url) {
			var audio = new Audio(url)
			audio.play()
			audio.volume = 0.0
		},
		getPreviousAnimationName() {
			let currentAnimationIndex = this.stepsPlaylist.indexOf(
				this.location.currentnode[this.paramkey]
			)

			return this.stepsPlaylist[
				(this.stepsPlaylist.length + (currentAnimationIndex - 1)) %
					this.stepsPlaylist.length
			]
		},
		getNextAnimationName() {
			let currentAnimationIndex = this.stepsPlaylist.indexOf(
				this.location.currentnode[this.paramkey]
			)

			return this.stepsPlaylist[
				(currentAnimationIndex + 1) % this.stepsPlaylist.length
			]
		},
		callPrevAnimation() {
			if (this.$route.name == 'section' && !this.animationLoading) {
				this.$router.push({
					name: 'section',
					params: { id: this.location.prevanimation[this.paramkey] }
				})
				this.$store.commit('CHANGE_STEP', 1)
				this.$store.commit('TOGGLE_ANIMATIONLOADING', true)
				this.$store.commit('TOGGLE_TOUR_STEPSKIP', false)
				this.$store.commit('TOGGLE_STEP_PAUSE_', false)
				this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
				this.$store.commit('TOGGLE_TOUR_STEPPLAYING', false)
			} else if (
				this.$route.name == 'ironmetabolism' &&
				!this.animationLoading
			) {
				//used for the swipe
				this.$router.push({
					name: 'section',
					params: { id: 'regulation1' }
				})
				this.$store.commit('CHANGE_STEP', 1)
				this.$store.commit('TOGGLE_ANIMATIONLOADING', true)
			}
		},
		callNextAnimation() {
			if (this.$route.name == 'section' && !this.animationLoading) {
				this.$router.push({
					name: 'section',
					params: { id: this.location.nextanimation[this.paramkey] }
				})
				this.$store.commit('CHANGE_STEP', 1)
				this.$store.commit('TOGGLE_ANIMATIONLOADING', true)
				this.$store.commit('TOGGLE_TOUR_STEPSKIP', false)
				this.$store.commit('TOGGLE_STEP_PAUSE_', false)
				this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
				this.$store.commit('TOGGLE_TOUR_STEPPLAYING', false)
			} else if (
				this.$route.name == 'ironmetabolism' &&
				!this.animationLoading
			) {
				//used for the swipe
				this.$router.push({
					name: 'section',
					params: { id: 'absorption1' }
				})
				this.$store.commit('CHANGE_STEP', 1)
				this.$store.commit('TOGGLE_ANIMATIONLOADING', true)
			}
		},
		seek(progress) {
			window.iron3d.seek(progress)
		}
	},

	beforeMount() {
		this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
	},
	mounted() {
		let iron3dScript = document.createElement('script')
		iron3dScript.id = 'iron3dscript'
		iron3dScript.src = './ironanimation/main.js?v=1.0'
		document.getElementById('animID2').appendChild(iron3dScript)
		const self = this

		if (!this.animationLoading) {
			var myElement = document.getElementById('animID2')
			var mc = new Hammer(myElement)
			mc.get('pinch').set({ enable: true })
			let this_ = this

			var posX = 0,
				posY = 0,
				scale = 1,
				last_scale = 1,
				last_posX = 0,
				last_posY = 0,
				max_pos_x = 0,
				max_pos_y = 0,
				transform = '',
				el = myElement

			/* eslint-disable */
			mc.on('doubletap pan pinch panend pinchend', function(ev) {
				// console.log(x)

				if (
					ev.type == 'doubletap' &&
					self.$vuetify.breakpoint.mdAndDown
				) {
					var div1 = document.getElementById('iron3d')
					var x1 = div1.getElementsByTagName('canvas')[0]
					x1.id = 'canvas-id'

					transform = 'translate3d(0, 0, 0) ' + 'scale3d(2, 2, 2) '
					scale = 2
					last_scale = 2
					try {
						if (
							window
								.getComputedStyle(el, null)
								.getPropertyValue('-webkit-transform')
								.toString() != 'matrix(1, 0, 0, 1, 0, 0)'
						) {
							transform =
								'translate3d(0, 0, 0) ' + 'scale3d(1, 1, 1) '
							scale = 1
							last_scale = 1
						}
					} catch (err) {
						console.log('error: ' + err)
					}
					el.style.webkitTransform = transform
					transform = ''
				}
				/* eslint-enable */

				if (scale != 1) {
					posX = last_posX + ev.deltaX
					posY = last_posY + ev.deltaY
					max_pos_x = Math.ceil(((scale - 1) * el.clientWidth) / 2)
					max_pos_y = Math.ceil(((scale - 1) * el.clientHeight) / 2)
					if (posX > max_pos_x) {
						posX = max_pos_x
					}
					if (posX < -max_pos_x) {
						posX = -max_pos_x
					}
					if (posY > max_pos_y) {
						posY = max_pos_y
					}
					if (posY < -max_pos_y) {
						posY = -max_pos_y
					}
				}

				if (ev.type == 'pinch') {
					this_.pinch = true
					var div2 = document.getElementById('iron3d')
					var x2 = div2.getElementsByTagName('canvas')[0]
					x2.id = 'canvas-id'
					scale = Math.max(0.999, Math.min(last_scale * ev.scale, 4))
				}
				if (ev.type == 'pinchend') {
					this_.pinch = false
					last_scale = scale
				}

				if (ev.type == 'pinchend' && scale == 0.999) {
					setTimeout(function() {
						scale = 1
					}, 200)
				}

				if (ev.type == 'pan') {
					this_.pinch = true
				}

				if (ev.type == 'panend') {
					this_.pinch = false
					if (scale != 1) {
						last_posX = posX < max_pos_x ? posX : max_pos_x
						last_posY = posY < max_pos_y ? posY : max_pos_y

						// console.log(scale)
					} else {
						// console.log(scale)

						if (ev.direction == 2) {
							this_.callNextAnimation()
						}

						if (ev.direction == 4) {
							this_.callPrevAnimation()
						}
					}
				}

				if (scale != 1) {
					transform =
						'translate3d(' +
						posX +
						'px,' +
						posY +
						'px, 0) ' +
						'scale3d(' +
						scale +
						', ' +
						scale +
						', 1)'
				}
				if (transform) {
					el.style.webkitTransform = transform
				}
			})
		}

		iron3dScript.onload = () => {
			let languages = ['en', 'de', 'fr', 'es']
			let previewId = 'none'
			let showLabels = false

			//user goes to section or iM from url (reloads page) sound should be on only if lang is english.
			if (this.$route.name == 'section') {
				let lang = this.$route.path
				let c_lang = lang.substring(0, 4)

				if (c_lang == '/en/') {
					this.$store.commit('TOGGLE_SOUND_ON', true)
				}

				// window.iron3d.setMute(true)
			}

			self.$on('CLICK_SOUND', () => {
				window.iron3d.playClickSound()
			})

			let getLastStepIndex = animationID => {
				switch (animationID) {
					case 'absorption1':
						return 8
					case 'absorption2':
						return 4
					case 'transport1':
						return 3
					case 'usage1':
						return 5
					case 'usage2':
						return 3
					case 'usage3':
						return 1
					case 'usage4':
						return 6
					case 'loss1':
						return 2
					case 'recycling1':
						return 4
					case 'storage1':
						return 3
					case 'regulation2':
						return 1
					case 'regulation3':
						return 4
				}
			}

			let getLastStepIndexOfPrevious = animationID => {
				switch (animationID) {
					case 'absorption1':
						return 4
					case 'absorption2':
						return 8
					case 'transport1':
						return 4
					case 'usage1':
						return 3
					case 'usage2':
						return 5
					case 'usage3':
						return 3
					case 'usage4':
						return 1
					case 'loss1':
						return 6
					case 'recycling1':
						return 2
					case 'storage1':
						return 4
					case 'regulation2':
						return 3
					case 'regulation3':
						return 1
				}
			}

			// STEP 1: Setup callbacks
			// To avoid confusion, everything tunnels throw this, no navigation is handled inside the app.
			window.iron3d.setPreviewCallback(id => {
				if (previewId == id) previewId = 'none'
				else previewId = id

				//window.iron3d.preview(previewId)
				self.$store.commit('SET_PREVIEW', previewId)
			})
			window.iron3d.setNavigationCallback(id => {
				if (id !== 'navigation') {
					self.$router.push({
						name: 'section',
						params: { id: id }
					})
				} else {
					self.$router.push({ name: 'ironmetabolism' })
				}
				//window.iron3d.navigate(id)
				self.$store.commit('CHANGE_STEP', 1)
				self.$store.commit('TOGGLE_TOUR_STEPJUMP', false)
				previewId = 'none' //we reset this one on every navigation event.
			})

			window.iron3d.setSelectionCallback(id => {
				setTimeout(function() {
					if (!self.pinch) {
						if (id) {
							//this.$store.commit('SET_ELEMENT', id)
							self.$router.push({
								name: 'section',
								params: {
									id:
										self.location.currentnode[
											self.paramkey
										],
									element: id
								}
							})
						} else {
							//this.$store.commit('EMPTY_ELEMENT')
							self.$router.push({
								name: 'section',
								params: {
									id: self.location.currentnode[self.paramkey]
								}
							})
						}
					} else {
						// no click
					}
				}, 100)
			})

			// Handle selectin if already set
			window.iron3d.setAnimationReadyCallback(
				(animationID, stepsProgresses) => {
					var this_ = this
					if (this.$route.name == 'section') {
						if (this.fromDL) {
							//FROM URL
							this.$store.commit('TOGGLE_ANIMATIONPLAYING')
							this.fromDL = false
							this.$store.commit('FROM_URL', false)
							// window.location.reload(true)
						}
					}

					if (this.$route.name == 'ironmetabolism') {
						setTimeout(function() {
							var ele = document.getElementsByClassName('play')
							for (var i = 0; i < ele.length; i++) {
								ele[i].id = 'playID'
							}

							document.getElementById(
								'playID'
							).onclick = function() {
								this_.playAudio('./audio/intro.mp3')
							}

							var q = document.getElementsByClassName(
								'quantities'
							)
							for (var x = 0; x < q.length; x++) {
								q[x].id = 'qID'
							}
						}, 100)
					}

					var url_ = new URL('/', location)
					var xhttp = new XMLHttpRequest()
					xhttp.open('HEAD', url_)
					xhttp.onreadystatechange = function() {
						if (this.readyState == this.DONE) {
							// console.log("status: " + this.status)
						}
					}
					xhttp.send()

					window.iron3d.lastStepIndex = getLastStepIndex(animationID)
					window.iron3d.lastStepIndexOfPrevious = getLastStepIndexOfPrevious(
						animationID
					)
					this.$store.commit('TOGGLE_ANIMATIONLOADING', false)
					this.$store.commit('TOGGLE_ANIMATIONREADY', true)
					this.animloaded = true
					this.call_once++
					if (this.call_once == 1) {
						this.$store.commit('TOGGLE_LABELS', true)
					}

					if (self.tourmode) {
						window.iron3d.setStepByStepMode(true)
						if (self.tourstepjump) {
							//progress bar jump to the last step when going backwards
							window.iron3d.jumpToStep(self.tourcurrentstep)
						} else {
							//progress bar jump to the first step when going forwards
							window.iron3d.playUntilStep(1)
						}
					} else {
						if (
							typeof self.$route.params.element !== 'undefined' &&
							self.$route.params.id == animationID
						) {
							window.iron3d.select(self.$route.params.element)
						}
					}
					self.$store.commit('SET_NAV_TRANSITIONING', false)
					self.stepsprogress = stepsProgresses
					//console.error('stepsProgresses', stepsProgresses)

					window.iron3d.setPlaybackProgressCallback(progress => {
						// This function gives you the progression on the animation ( 0 to 1 ).
						//if (!self.manualprogress) {
						self.playprogress = progress
						//self.$store.commit('SET_ANIMATIONPROGRESS', progress)
						//}
						//console.log(progress)
					})
				}
			)

			// Handle Step reached
			//toursteptransitioning
			window.iron3d.setStepReachedCallback(() => {
				// animationID, stepIndex, lastStep
				// this.$store.commit('TOGGLE_ANIMATIONPLAYING')
				self.$store.commit('TOGGLE_TOUR_STEPJUMP', false)
				self.$store.commit('TOGGLE_TOUR_STEPTRANSITIONING', false)
			})

			/* eslint-disable */
			window.iron3d.setEndReachedCallback(
				(animationID, stepIndex, lastStep) => {
					// console.log(animationID)
					// console.log(stepIndex)
					// console.log(lastStep)
					this.$router.push({
						name: 'section',
						params: {
							id: this.location.nextanimation[this.paramkey]
						}
					})
					this.$store.commit('CHANGE_STEP', 1)
					this.$store.commit('SELECTED_ELEMENT_NAME_EX', 'none')
				}
			)
			/* eslint-enable */

			// STEP 2: Launch
			let navigation =
				this.$route.name == 'section'
					? this.$route.params.id
					: 'navigation'
			let normalzoom = this.$vuetify.breakpoint.mdAndDown ? false : true
			window.iron3d.launch(
				this.currentlang,
				navigation,
				false,
				normalzoom
			)
			if (!self.sound) {
				window.iron3d.setMute(true)
			}
			window.iron3d.playUntilStep(1)
			//optionally you can set the animation.
			//window.iron3d.launch( 'en', "transport1" );

			// STEP 3: Now you can interact with the app.
			window.addEventListener('keydown', ke => {
				switch (ke.code) {
					case 'KeyL':
						languages.push(languages.shift())
						window.iron3d.setLanguage(languages[0]) //change the language.
						break

					case 'KeyT':
						showLabels = !showLabels
						//window.iron3d.showLabels(showLabels) //hide or show labels. On the main navigation that's the quantities.
						self.$store.commit('TOGGLE_LABELS')
						break

					case 'KeyP':
						if (previewId == 'all') previewId = 'none'
						else previewId = 'all'
						window.iron3d.preview(previewId)
						break
				}
			})

			/* window.iron3d.setBlockingLoadingOverCallback(() => {
				console.log('setBlockingLoadingOverCallback')
				self.animloaded = true
			}) */
		}
		// } else {
		// 	console.log(' not iron - - - no scr')
		// }
	},

	beforeDestroy() {
		//	console.log('beforeDestroy called')
		this.$store.commit('TOGGLE_ANIMATIONPLAYING', false)
		// Include here estroy call of the animation
	}
}
</script>

<style lang="scss">
.iron3d-controls #up:before,
#iron3d .regulation1.icon:before {
	top: 0;
	left: 0;
}

#iron3d .navigation.play.button {
	&:after {
		display: inline-block;
		font: normal normal normal 24px/1 'Material Design Icons';
		font-size: inherit;
		text-rendering: auto;
		line-height: inherit;
		-webkit-font-smoothing: antialiased;
		content: '\F040A';
		font-size: 2.6em;
	}
	&:hover:after {
		color: #9d1f30;
	}
}

.fill-width {
	width: 100%;
}

.animationcapture {
	position: absolute;
}
.animation-section {
	max-width: 100%;
	background: rgb(187, 189, 222);
	background: linear-gradient(
		0deg,
		rgba(187, 189, 222, 1) 0%,
		rgba(255, 255, 255, 1) 100%
	);
}
.iron3d-controls #top {
	display: none;
}
.blink_me {
	z-index: 999999;
	position: absolute;
	right: 15px;
	top: 15px;
	font-size: 16px;
	animation: blinker 2s linear infinite;
}
#loading-wrapper {
	z-index: 999999;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

#canvas-id {
	z-index: -1;
	// pointer-events: none;
}

#img-id {
	z-index: -1;
}

#animID {
	overflow: hidden;
}

// #qID{
// 	margin-top: -2px;
// }

// #qID.alignRight{
// 	margin-right: 3.5px;
// }

// #qID.alignLeft{
// 	margin-left: 3.5px;
// }

// @media only screen and (max-width: 800px) {
// #qID{
// 	margin-top: -3px;
// }

// }
</style>
