var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-breadcrumbs',{staticClass:"pl-0 flex-nowrap",attrs:{"light":"","items":_vm.location.pathlist},scopedSlots:_vm._u([{key:"item",fn:function(props){return [(
				_vm.isIMLanding ||
					(_vm.isSection && _vm.$vuetify.breakpoint.lgAndUp) ||
					props.item.ishome ||
					props.item.current
			)?_c('v-breadcrumbs-item',{class:!_vm.$vuetify.breakpoint.lgAndUp ? 'br_mobile' : '',attrs:{"to":{
				name: props.item.route,
				params: { id: props.item[_vm.paramkey] }
			},"active-class":props.item.current ? 'v-breadcrumbs__item--disabled' : ''}},[(!props.item.ishome)?[_vm._v(" "+_vm._s(props.item.name)+" ")]:_vm._e()],2):_vm._e(),(_vm.isMicrosite || _vm.isSecondaryNavigation || props.item.ishome)?_c('v-breadcrumbs-item',{class:!_vm.$vuetify.breakpoint.lgAndUp ? 'px-0' : '',attrs:{"to":{
				name: props.item.route,
				params: { id: props.item[_vm.paramkey] }
			},"active-class":props.item.current ? 'v-breadcrumbs__item--disabled' : ''}},[(props.item.ishome)?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-home")]):_vm._e(),(props.item.ishome && _vm.$vuetify.breakpoint.lgAndUp)?[_c('span',[_vm._v(_vm._s(_vm.$t('appname')))])]:_vm._e(),(!props.item.ishome)?[_vm._v(" "+_vm._s(props.item.name)+" ")]:_vm._e()],2):_vm._e(),(
				(_vm.$vuetify.breakpoint.lgAndUp || props.item.ishome) &&
					props.item.childs &&
					props.item.childs.length &&
					!_vm.isMicrosite &&
					!_vm.isSecondaryNavigation
			)?_c('v-menu',{attrs:{"light":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: breadcrumb }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},{ ...breadcrumb }),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},[_c('v-list',{staticClass:"nav-dropdown"},[_vm._l((props.item.childs),function(child,ind){return [_c('v-list-item',{key:ind,attrs:{"to":{
							name: child.route,
							params: { id: child[_vm.paramkey] }
						}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])],1),(_vm.$vuetify.breakpoint.mdAndDown)?_vm._l((child.childs),function(subchild,subind){return _c('v-list-item',{key:ind + '-' + subind,attrs:{"to":{
								name: 'section',
								params: { id: subchild[_vm.paramkey] }
							}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(subchild.name)+" ")],1)],1)}):_vm._e()]})],2)],1):_vm._e(),(
				(_vm.isMicrosite || _vm.isSecondaryNavigation) &&
					props.item.ishome &&
					props.item.childs.length
			)?_c('v-menu',{attrs:{"light":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: breadcrumb }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},{ ...breadcrumb }),[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)},[_c('v-list',{staticClass:"nav-dropdown"},[_vm._l((props.item.childs),function(child,ind){return [_c('v-list-item',{key:ind,attrs:{"to":{
							name: child.route,
							params: { id: child[_vm.paramkey] }
						}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])],1),_vm._l((child.childs),function(subchild,subind){return _c('v-list-item',{key:ind + '-' + subind,attrs:{"to":{
								name: 'section',
								params: { id: subchild[_vm.paramkey] }
							}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(subchild.name)+" ")],1)],1)})]})],2)],1):_vm._e()]}},{key:"divider",fn:function(){return [_c('v-icon')]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }