<template>
	<v-app :class="savescr != 0 ? 'scr-mode' + mobileclass : mobileclass">
		<LeftRegion v-if="!homeState" />
		<Toolbar />
		<!-- <TourPanel /> -->
		<v-content id="c-wrap">
			<keep-alive>
				<AnimationPlayer v-if="isIM" />
			</keep-alive>
			<HomeView v-if="homeState" />
			<MicrositeView v-if="isMicrosite" />
			<SecondaryNavigationView v-if="isSecondaryNavigation" />
		</v-content>
		<InformationDialog />
		<ElementDetailsDialog />
		<ElementsTable />
		<CopyrightWatermark v-if="savescr != 0" />
		<Referenes />
		<SWUpdatePopup />
		<v-overlay
			id="screenshot-overlay"
			:value="savescr != 0"
			z-index="200"
			class="text-center"
		>
			<v-progress-circular indeterminate size="64"></v-progress-circular>
			<div class="mt-3">{{ $t('screenshot_loader') }}</div>
		</v-overlay>
	</v-app>
</template>

<script>
import HomeView from './views/Home'
import MicrositeView from './views/Microsite'
import SecondaryNavigationView from './views/SecondaryNavigation'
import LeftRegion from './components/LeftRegion'
import AnimationPlayer from './components/AnimationPlayer'
import Toolbar from './components/Toolbar'
import InformationDialog from './components/InformationDialog'
import ElementDetailsDialog from './components/ElementDetailsDialog'
import ElementsTable from './components/ElementsTable'
import Referenes from './components/Referenes'
//import TourPanel from './components/TourPanel'
import CopyrightWatermark from './components/CopyrightWatermark'
import SWUpdatePopup from './components/SWUpdatePopup'

import store from '@/store/store.js'

import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default {
	name: 'App',
	data() {
		return {
			overlay: true
		}
	},
	computed: {
		...mapState(['currentlang', 'savescr', 'animationscriptloaded']),
		...mapGetters([
			'homeState',
			'isIM',
			'isIMLanding',
			'isMicrosite',
			'isSecondaryNavigation'
		]),
		mobileclass() {
			let mobileclass = ''
			if (this.$vuetify.breakpoint.mdAndDown) {
				mobileclass = 'mobile-view'
			}
			return mobileclass
		}
	},
	components: {
		HomeView,
		MicrositeView,
		SecondaryNavigationView,
		Toolbar,
		LeftRegion,
		AnimationPlayer,
		InformationDialog,
		ElementDetailsDialog,
		ElementsTable,
		Referenes,
		//TourPanel,
		CopyrightWatermark,
		SWUpdatePopup
	},
	watch: {
		$route(to, from) {
			if (to.params.locale != from.params.locale) {
				store.dispatch('fetchData')
			}

			if (to.name == 'ironmetabolism' || to.name == 'section') {
				var elem1 = document.getElementById('c-wrap')
				elem1.style.overflow = 'hidden'
			} else {
				var elem2 = document.getElementById('c-wrap')
				elem2.style.overflow = 'auto'
			}
		}
	},
	mounted() {
		if (this.$vuetify.breakpoint.mdAndDown) {
			window.addEventListener('beforeinstallprompt', e => {
				// console.log('beforeinstallprompt Event fired')
				e.preventDefault()
				// this.deferredPrompt = e
				// console.log(deferredPrompt)
				// this.$store.commit('UPDATE_POPUP', false)
			})
		}

		if (this.$vuetify.breakpoint.mdAndDown) {
			this.$store.commit('TOGGLE_MINIMAP')
		}
		store.dispatch('fetchData')

		if (
			this.$route.name == 'ironmetabolism' ||
			this.$route.name == 'section'
		) {
			var elem3 = document.getElementById('c-wrap')
			elem3.style.overflow = 'hidden'
		} else {
			var elem4 = document.getElementById('c-wrap')
			elem4.style.overflow = 'auto'
		}
	},
	created() {
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('./service-worker.js')
		}
		document.addEventListener('click', function(event) {
			if (event.target.getAttribute('id') == 'close-pc-btn-handler') {
				window.OneTrust.Close()
			}
		})
	}
}
</script>

<style>
.mainnav-link {
	background-color: #ffffff !important;
}
.scrcanvas {
	position: fixed;
	top: 0;
	z-index: 100000;
	visibility: hidden;
}

.v-application--wrap {
	min-height: -webkit-fill-available !important;
	overflow: hidden;
}

.v-content {
	background: linear-gradient(0deg, #bbbdde 0%, white 100%);
}

@media only screen and (max-width: 1263px) {
	.v-content {
		background: #bcbedf;
	}
}

/* .v-content__wrap {
	overflow: auto;
} */
</style>
