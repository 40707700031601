<template>
	<div class="left-column-wraper d-flex flex-column">
		<div class="flex-grow-1">
			<v-list class="pb-0 pt-0">
				<v-list-item
					class="section-nav-link"
					active-class="active"
					@click="$router.push({ name: 'ironmetabolism' })"
				>
					<v-list-item-icon>
						<v-icon class="icon_ie">mdi-play-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title
							class="text-uppercase subtitle-1 font-weight-medium"
							>{{ mainNavigation.name }}</v-list-item-title
						>
					</v-list-item-content>
					<v-list-item-icon>
						<v-icon class="icon_ie"
							>mdi-chevron-right-box-outline</v-icon
						>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
			<v-divider></v-divider>
			<v-list class="pb-0 pt-0">
				<template v-for="(item, ind) in micrositeNavigation.childs">
					<v-list-item
						:key="item.cid"
						:to="{
							name: item.route,
							params: { id: item[paramkey] }
						}"
						class="section-nav-link"
						@click="closeDrawer()"
						active-class="active"
					>
						<v-list-item-icon>
							<v-icon class="icon_ie">mdi-newspaper</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title
								class="subtitle-1 font-weight-regular"
								>{{ item.name }}</v-list-item-title
							>
						</v-list-item-content>
						<v-list-item-icon>
							<v-icon class="icon_ie">mdi-chevron-right</v-icon>
						</v-list-item-icon>
					</v-list-item>
					<v-divider :key="ind"></v-divider>
				</template>
			</v-list>
		</div>

		<v-list class="pb-0 pt-0">
			<v-divider></v-divider>
			<template v-for="(item, ind) in secondaryNavigation.childs">
				<v-list-item
					:key="item.cid"
					:to="{
						name: item.route,
						params: { id: item[paramkey] }
					}"
					class="section-nav-link"
					active-class="active"
				>
					<!-- <v-list-item-icon>
						<v-icon>mdi-newspaper</v-icon>
					</v-list-item-icon>-->
					<v-list-item-content>
						<v-list-item-title
							class="subtitle-1 font-weight-regular"
							>{{ item.name }}</v-list-item-title
						>
					</v-list-item-content>
					<v-list-item-icon>
						<v-icon class="icon_ie">mdi-chevron-right</v-icon>
					</v-list-item-icon>
				</v-list-item>
				<v-divider :key="ind"></v-divider>
			</template>
			<v-list-item
				class="section-nav-link"
				active-class="active"
				href="https://privacy.csl.com/"
				target="_blank"
			>
				<v-list-item-content>
					<v-list-item-title class="subtitle-1 font-weight-regular">
						{{ $t('privacy_policy_app') }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon class="icon_ie">mdi-chevron-right</v-icon>
				</v-list-item-icon>
			</v-list-item>
			<v-divider :key="ind"></v-divider>
			<v-list-item
				class="section-nav-link"
				active-class="active"
				:to="{
					name: 'secondaryNavigation',
					params: { id: 'cookie-policy' }
				}"
			>
				<v-list-item-content>
					<v-list-item-title class="subtitle-1 font-weight-regular">
						{{ $t('cookie_policy_app') }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon class="icon_ie">mdi-chevron-right</v-icon>
				</v-list-item-icon>
			</v-list-item>
			<v-list-item
				class="section-nav-link"
				active-class="active"
				@click="toggleCookieSettings"
			>
				<v-list-item-content>
					<v-list-item-title class="subtitle-1 font-weight-regular">
						{{ $t('cookie_setting_app') }}
					</v-list-item-title>
				</v-list-item-content>
				<v-list-item-icon>
					<v-icon class="icon_ie">mdi-open-in-new</v-icon>
				</v-list-item-icon>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'IMLandingLeft',
	components: {},
	data: () => ({
		panels: [],
		allpanels: false,
		cookiesettingbtnlabel: 'Cookie Settings'
	}),
	computed: {
		...mapState([
			'mainNavigation',
			'micrositeNavigation',
			'previewpanels',
			'showlabels',
			'paramkey',
			'secondaryNavigation',
			'drawer'
		])
	},
	created() {
		if (window.OneTrust) {
			this.cookiesettingbtnlabel = window.OneTrust.GetDomainData().CookieSettingButtonText
		}
	},
	methods: {
		closeDrawer() {
			if (this.$vuetify.breakpoint.mdAndDown) {
				this.$store.commit('TOGGLE_DRAWER', false)
			}
		},
		toggleCookieSettings() {
			if (window.OneTrust) {
				window.OneTrust.ToggleInfoDisplay()
			}
		}
	}
}
</script>

<style scoped>
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.icon_ie {
		top: 14px;
	}
}
</style>
