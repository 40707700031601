<template>
	<div
		class="fill-height"
		v-bind:class="{ 'text-full': showFullText, 'text-half': !showFullText }"
	>
		<div class="cc">
			<!-- <CookieConsent v-if="currentlangobj.id == 'en'">
				<template slot="message">
					<p><span v-html="myJson[0].en[0].message"></span></p>
				</template>
				<template slot="button">
					<button class="btn btn-info">
						<span v-html="myJson[0].en[0].btn"></span>
					</button>
				</template>
			</CookieConsent>

			<CookieConsent v-if="currentlangobj.id == 'de'">
				<template slot="message">
					<p><span v-html="myJson[0].de[0].message"></span></p>
				</template>
				<template slot="button">
					<button class="btn btn-info">
						<span v-html="myJson[0].de[0].btn"></span>
					</button>
				</template>
			</CookieConsent>

			<CookieConsent v-if="currentlangobj.id == 'fr'">
				<template slot="message">
					<p><span v-html="myJson[0].fr[0].message"></span></p>
				</template>
				<template slot="button">
					<button class="btn btn-info">
						<span v-html="myJson[0].fr[0].btn"></span>
					</button>
				</template>
			</CookieConsent> -->
		</div>
		<v-container
			class="animation-section fill-height"
			v-bind:class="{
				'text-full': showFullText,
				'text-half': !showFullText
			}"
		>
			<div
				v-if="dataloaded"
				class="home d-flex flex-column"
				v-bind:class="{
					'left-column-wraper-full': showFullText,
					'left-column-wraper-half': !showFullText
				}"
			>
				<div>
					<v-card outlined class="pa-2 home-card">
						<v-card-title class="headline mb-1">
							{{ navigation.cititle }}
						</v-card-title>
						<div class="t">
							<v-card-text
								v-if="!showFullText"
								class="home-text-half"
								v-html="navigation.fc"
							></v-card-text>
							<v-icon
								v-if="!showFullText"
								class="rm"
								@click="showText"
								>mdi-chevron-down</v-icon
							>
							<v-card-text
								v-if="showFullText"
								class="home-text-full"
								v-html="navigation.fc"
							></v-card-text>
							<v-icon
								v-if="showFullText"
								class="rm"
								@click="hideText"
								>mdi-chevron-up</v-icon
							>
						</div>
						<v-card-actions>
							<div class="btns">
								<div class="btns_start">
									<v-btn
										@click="go_to_iM"
										depressed
										class="start-app-btn"
										dark
										color="rgb(0, 102, 255)"
										>{{ $t('start_app') }}</v-btn
									>
								</div>

								<div class="btns_download">
									<v-btn
										v-if="windows"
										outlined
										color="rgb(0, 102, 255)"
										class="install-app-btn"
										@click="install_btn_tracker"
										:style="{ display: installBtn }"
										>{{ $t('install_app') }}</v-btn
									>

									<!-- <a
										:href="url_android_download"
										download
										v-if="
											$vuetify.breakpoint.xs &&
												androidOS &&
												!windows &&
												onBrowser &&
												!isPWA &&
												isAvailableAndroid &&
												!isNative
										"
									>
										<v-btn
											class="install-app-btn"
											outlined
											color="rgb(0, 102, 255)"
											>{{ $t('open_app') }}</v-btn
										>
									</a>

									<a
										:href="url_ios_download"
										download
										v-if="
											$vuetify.breakpoint.xs &&
												iOS &&
												!windows &&
												onBrowser &&
												!isPWA &&
												isAvailableIos
										"
									>
										<v-btn
											class="install-app-btn"
											outlined
											color="rgb(0, 102, 255)"
											>{{ $t('open_app') }}</v-btn
										>
									</a> -->
								</div>
							</div>
						</v-card-actions>
					</v-card>

					<v-list class="pb-0 resnews flex-grow-1">
						<template
							v-for="(item, ind) in micrositeNavigation.childs"
						>
							<v-list-item
								:key="item.cid"
								:to="{
									name: item.route,
									params: { id: item[paramkey] }
								}"
								class="section-nav-link"
								active-class="active"
							>
								<v-list-item-content>
									<v-list-item-title
										class="text-uppercase subtitle-1 font-weight-medium"
										>{{ item.name }}</v-list-item-title
									>
								</v-list-item-content>
								<v-list-item-icon>
									<v-icon>mdi-chevron-right</v-icon>
								</v-list-item-icon>
							</v-list-item>
							<v-divider :key="ind"></v-divider>
						</template>
					</v-list>
				</div>
				<HomeFooter
					class="footer"
					v-if="$vuetify.breakpoint.mdAndDown"
				></HomeFooter>
			</div>
			<span class="bg"></span>
		</v-container>
		<HomeFooter v-if="$vuetify.breakpoint.lgAndUp"></HomeFooter>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import HomeFooter from '../components/HomeFooter'
//import CookieConsent from 'vue-cookieconsent-component'
import json from '../../public/data/cookiePolicyMessage.json'
export default {
	name: 'Home',
	methods: {
		go_to_iM() {
			this.$router.push({ name: 'ironmetabolism' })
		},
		install_btn_tracker() {
			/* this.$gtag.event('install_app', {
				event_category: 'app_installation',
			}) */
			this.$gtm.trackEvent({
				category: 'app_installation',
				label: 'PWA Installation',
				value: 1
			})
			this.installBtn = 'none'
			this.installApp = false
			this.deferredPrompt.prompt()
			this.deferredPrompt.userChoice.then(choiceResult => {
				if (choiceResult.outcome === 'accepted') {
					// console.log('User accepted the A2HS prompt')
				} else {
					// console.log('User dismissed the A2HS prompt')
					//
				}
				this.deferredPrompt = null
			})
		},
		showText() {
			this.showFullText = true
		},
		hideText() {
			this.showFullText = false
		}
	},
	components: {
		HomeFooter
		//CookieConsent
	},

	data: () => ({
		installBtn: 'none',
		installApp: false,
		isPWA: false,
		url_android_download: null,
		url_ios_download: null,
		installer: undefined,
		deferredPrompt: undefined,
		showFullText: false,
		androidOS: false,
		iOS: false,
		windows: false,
		onBrowser: false,
		isAvailableAndroid: false,
		isAvailableIos: false,
		ua: false,
		isNative: false,

		myJson: json
	}),
	computed: {
		...mapState([
			'navigation',
			'mainNavigation',
			'micrositeNavigation',
			'paramkey',
			'secondaryNavigation',
			'animationReady',
			'dataloaded',
			'updatePopup'
		]),
		...mapGetters(['currentlangobj'])
	},
	mounted() {
		// console.log("changed")
		let self = this
		this.$gtm.trackView('Home', this.$route.fullPath)
		window.addEventListener('beforeinstallprompt', e => {
			// console.log('beforeinstallprompt Event fired')
			e.preventDefault()
			this.deferredPrompt = e
			// console.log(deferredPrompt)
			if (this.$vuetify.breakpoint.mdAndUp) {
				this.installBtn = 'block'
				this.installApp = true
				this.$store.commit('UPDATE_POPUP', false)
			}
		})
		/* eslint-disable */
		// console.log('')

		//check if app is running on windows
		var platform = ['Win32']
		for (var i = 0; i < platform.length; i++) {
			if (navigator.platform.indexOf(platform[i]) > -1) {
				this.windows = true
			}
		}

		//check what OS the app is running in
		var isMobile = {
			Android: function () {
				return navigator.userAgent.match(/Android/i)
			},
			iOS: function () {
				return navigator.userAgent.match(/iPhone|iPad|iPod/i)
			},
			any: function () {
				return isMobile.Android() || isMobile.iOS()
			},
		}

		if (isMobile.Android()) {
			this.androidOS = true
		}
		if (isMobile.iOS()) {
			this.iOS = true
		}

		//check if the app is open in browser or natively
		if (
			(navigator.userAgent.indexOf('Opera') ||
				navigator.userAgent.indexOf('OPR')) != -1
		) {
			this.onBrowser = true
		} else if (navigator.userAgent.indexOf('Chrome') != -1) {
			this.onBrowser = true
		} else if (navigator.userAgent.indexOf('Safari') != -1) {
			this.onBrowser = true
		} else if (navigator.userAgent.indexOf('Firefox') != -1) {
			this.onBrowser = true
		} else if (
			navigator.userAgent.indexOf('MSIE') != -1 ||
			!!document.documentMode == true
		) {
			//IF IE > 10
			this.onBrowser = true
		} else {
			this.onBrowser = false
		}

		self.ua = navigator.userAgent
		let sub = 'IronAtlasAndroid/1.0'
		if (self.ua.includes(sub)) {
			self.isNative = true
		}

		//check if app is running natively

		// if(navigator.userAgent.indexOf(''))

		//if running as pwa
		if (window.matchMedia('(display-mode: standalone)').matches) {
			this.isPWA = true
		} else {
			this.isPWA = false
		}

		if (this.androidOS && !this.isPWA) {
			this.$store.commit('UPDATE_POPUP', false)
		}
		if (this.iOS && !this.isPWA) {
			this.$store.commit('UPDATE_POPUP', false)
		}

		if (this.$vuetify.breakpoint.smAndDown) {
			//IOS CHECK

			//TO DO :: CHECK IF APP IS INSTALLED OR NOT
			if (this.iOS) {
				var url_ios_check = new URL('/app/ios/manifest.plist', location)
				var xhttp = new XMLHttpRequest()
				xhttp.open('HEAD', url_ios_check)
				xhttp.onreadystatechange = function () {
					if (this.readyState == this.DONE) {
						console.log(
							'ios status -- ' + location + ' : ' + this.status
						)

						if (this.status == 200) {
							self.isAvailableIos = true
							var host = window.location.hostname
							if (host == 'ironatlas.arkit.ch') {
								self.url_ios_download =
									'itms-services://?action=download-manifest&url=https://ironatlas.arkit.ch/app/ios/manifest.plist'
							} else {
								self.url_ios_download =
									'itms-services://?action=download-manifest&url=https://ironatlas.medline.ch/app/ios/manifest.plist'
							}
						}
					}
				}
				xhttp.send()
			}

			// ANDROID CHECK
			if (this.androidOS) {
				var url_android_check = new URL('/app/ironatlas.apk', location)
				var xhttp = new XMLHttpRequest()
				xhttp.open('HEAD', url_android_check)
				xhttp.onreadystatechange = function () {
					if (this.readyState == this.DONE) {
						console.log(
							'android status -- ' + location + ': ' + this.status
						)

						if (this.status == 200) {
							self.isAvailableAndroid = true
							// self.url_android_download = new URL('/app/ironatlas.apk', location)

							var host = window.location.hostname
							if (host == 'ironatlas.arkit.ch') {
								self.url_android_download =
									'intent://ironatlas.medline.ch/#Intent;scheme=ironatlas;S.browser_fallback_url=https%3A%2F%2Fironatlas.arkit.ch%2Fapp%2Fironatlas.apk;end;'
							} else {
								self.url_android_download =
									'intent://ironatlas.medline.ch/#Intent;scheme=ironatlas;S.browser_fallback_url=https%3A%2F%2Fironatlas.medline.ch%2Fapp%2Fironatlas.apk;end;'
							}
						}
					}
				}
				xhttp.send()
			}
		}

		/* eslint-enable */
	}
}
</script>

<style scoped lang="scss">
// required and to get updates
//@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent';
// example or use it
//@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom';
//@import './node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition';

.left-column-wraper-full {
	max-width: 500px;
	left: 5vw;
	margin-right: 5vw;
	position: absolute;
	z-index: 1;
	justify-content: center;
	top: 7%;
}

.left-column-wraper-half {
	max-width: 500px;
	left: 5vw;
	margin-right: 5vw;
	position: absolute;
	z-index: 1;
	justify-content: center;
	top: 7%;
}

a {
	text-decoration: none;
}

.text-full {
	height: 100%;
}

.text-half {
	height: 95%;
}

.container {
	padding: 0%;
}

.theme--light.v-list {
	background-color: rgb(1, 1, 1, 0);
}

.theme--light.v-card {
	background-color: rgb(1, 1, 1, 0);
	border: dotted thin rgba(0, 0, 0, 0.4) !important;
}

.cc {
	position: absolute;
}

.resnews {
	padding-top: 50px;
}

.headline {
	font-weight: 700 !important;
	/*text-transform: uppercase;*/
	word-break: break-word;
}

.install-app-btn {
	border: dotted thin rgba(0, 0, 0, 0.4) !important;
	border-color: rgb(255, 0, 0) !important;
	margin-right: 10px;
	margin-bottom: 10px;
}
.start-app-btn {
	margin-right: 10px;
	margin-bottom: 10px;
}

.bg {
	width: 100%;
	height: 850px;
	margin-left: 10%;
	background: url('../assets/homebackground24.jpg') no-repeat center;
	background-size: contain;
	transform: scale(1);
	opacity: 0.4;
	position: fixed;
	top: 40px;
	pointer-events: none;
}

.v-card__text {
	color: rgb(0, 0, 0) !important;
}

.v-content {
	background-color: #bbbdde;
}

.v-card {
	background-color: #00000000;
}

.cookie-consent {
	bottom: 0% !important;
	right: 0% !important;
	width: 30% !important;
	left: 70% !important;

	margin-left: -20px;
	margin-bottom: 20px;

	padding: 1.3em;
	text-align: justify;

	background: #9d1f30 !important;
	color: white;

	border-radius: 5px;
}

.btn-info {
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 5px;
	padding-bottom: 5px;
	background: #630e1a;
	border-radius: 5px;
	margin-top: 10px;
}

@media only screen and (max-width: 1400px) {
	.bg {
		width: 100%;
		height: 850px;
		margin-left: 20vw;
		background: url('../assets/homebackground24.jpg') no-repeat center
			center;
		background-size: cover;
		transform: scale(1);
		opacity: 0.4;
	}
}

@media only screen and (max-width: 1263px) {
	.left-column-wraper-half {
		max-width: 500px;
		left: 5vw;
		margin-right: 5vw;
		position: absolute;
		z-index: 1;
		// justify-content: center;
		justify-content: space-between;
		top: 7%;
		height: 92%;
	}

	.home-text-half {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-height: 1.5; /* fallback */
		max-height: 6 * 1.5em; /* fallback */
	}
}

@media only screen and (max-width: 600px) {
	.cookie-consent {
		bottom: 0% !important;
		right: 0% !important;
		width: 100% !important;
		left: 0% !important;

		margin-left: 0px;
		margin-bottom: 0px;

		padding: 2em;
		text-align: justify;
	}

	.bg {
		width: 100%;
		height: 850px;
		margin-left: 20vw;
		background: url('../assets/homebackground24.jpg') no-repeat center
			center;
		background-size: cover;
		transform: scale(1);
		opacity: 0.1;
	}

	.v-card {
		background-color: #00000000 !important;
	}
}

.online-disabled {
	cursor: default;
}

.home-text-half {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6; /* number of lines to show */
	line-height: 1.6; /* fallback */
	max-height: 7 * 1.5em; /* fallback */
}

.home-text-full {
	padding-bottom: 0px;
}

.v-card__actions {
	padding-left: 15px;
}

.t {
	display: flex;
	flex-direction: column;
}

.v-icon.v-icon::after {
	content: none !important;
}

.btns {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
</style>
