<template>
	<div class="scroll">
		<v-card tile class="dialog-content mt-auto">
			<v-card-title class="pa-0">
				<v-toolbar flat dark class="v-sheet-bg">
					<v-toolbar-title>{{
						$t('cookie_policy_app')
					}}</v-toolbar-title>
				</v-toolbar>
			</v-card-title>
			<v-card-text class="py-5">
				<div>
					<!-- OneTrust Cookies List start -->
					<div id="ot-sdk-cookie-policy">&nbsp;</div>
					<!-- OneTrust Cookies List end -->
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'CookiePolicy',
	computed: {
		...mapState(['persistdialog'])
	},
	methods: {},
	mounted() {
		if (window.OneTrust) {
			window.OneTrust.initializeCookiePolicyHtml()
		}
		/* this.$gtag.pageview({
			page_title: 'Cookie Policy'
		}) */
		this.$gtm.trackView('Cookie Policy', this.$route.fullPath)
	}
}
</script>

<style lang="scss" scoped>
.scroll {
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	// position: absolute;
	background: linear-gradient(0deg, #bbbdde 0%, white 100%);
}

.dialog-content {
	top: 50px;
	width: 65%;
	margin-bottom: 170px !important;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
}

@media only screen and (max-width: 768px) {
	.dialog-content {
		width: 90%;
	}
}
</style>
