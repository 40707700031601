<template>
	<div class="scroll">
		<v-card tile class="dialog-content mt-auto">
			<v-card-title class="pa-0">
				<v-toolbar flat dark class="v-sheet-bg">
					<v-toolbar-title>{{ maindata.name }}</v-toolbar-title>
				</v-toolbar>
			</v-card-title>
			<v-card-text class="py-5">
				<div v-html="maindata.ciextra.fc"></div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'LegalNotice',
	computed: {
		...mapState(['persistdialog']),
		legaldialog: {
			get() {
				return this.$store.state.legaldialog
			},
			set(val) {
				this.$store.commit('SET_LEGAL_DIALOG', val)
			}
		},
		maindata() {
			var data = {
				name: 'Legal Notice',
				ciextra: {
					fc: ''
				}
			}
			if (
				typeof this.$store.getters.secondaryNavigation.childs !==
				'undefined'
			) {
				// data = this.$store.getters.secondaryNavigation.childs[0]
				var result = this.$store.getters.secondaryNavigation.childs.filter(
					obj => {
						return obj.refcode === 'legal-notice'
					}
				)
				data = result[0]

				data.ciextra = this.$store.getters.getCIExtra(data.cid)
			}
			return data
		}
	},
	methods: {},
	mounted() {
		/* this.$gtag.pageview({
			page_title: 'Legal Notice',
		}) */
		this.$gtm.trackView('Legal Notice', this.$route.fullPath)
	}
}
</script>

<style lang="scss" scoped>
.scroll {
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	// position: absolute;
	background: linear-gradient(0deg, #bbbdde 0%, white 100%);
}

.dialog-content {
	top: 50px;
	width: 65%;
	margin-bottom: 170px !important;
	left: 50%;
	-webkit-transform: translate(-50%);
	transform: translate(-50%);
}

@media only screen and (max-width: 768px) {
	.dialog-content {
		width: 90%;
	}
}
</style>
