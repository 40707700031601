<template>
	<div id="AnimationPlayerToolbar" v-if="animationReady">
		<v-toolbar dense flat color="grey lighten-3" height="40">
			<v-toolbar-items>
				<v-btn icon @click="openSection">
					<v-icon>mdi-play</v-icon>
				</v-btn>
			</v-toolbar-items>
			<v-spacer></v-spacer>
			<v-toolbar-items>
				<v-tooltip top v-if="$vuetify.breakpoint.lgAndUp">
					<template v-slot:activator="{ on }">
						<v-btn
							light
							icon
							@click="toggleFullscreen"
							v-on="on"
							:class="isfullscreen ? 'v-btn--active' : ''"
						>
							<v-icon v-if="!isfullscreen">mdi-fullscreen</v-icon>
							<v-icon v-if="isfullscreen">mdi-fullscreen-exit</v-icon>
						</v-btn>
					</template>
					<span v-if="!isfullscreen">{{ $t('fullscreen_on') }}</span>
					<span v-if="isfullscreen">{{ $t('fullscreen_off') }}</span>
				</v-tooltip>
			</v-toolbar-items>
		</v-toolbar>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
	name: 'AnimationPlayerToolbar',
	data: () => ({}),
	computed: {
		...mapState([
			'navigation',
			'paramkey',
			'dataloaded',
			'isfullscreen',
			'sound',
			'cc_enabled',
			'animationplaying',
			'manualprogress',
			'animationprogress',
			'savescr',
			'navtransitioning',
			'animationLoading',
			'animationReady',
			'animationLastStep',
			'tour_mode',
			'tourmode',
			'tourautoplay',
			'sound_enabled',
			'tourlist',
			'tourcurrentstep',
			'toursteptransitioning',
			'tourstepplaying'
		]),
		...mapGetters(['location', 'isSection', 'isIMLanding'])
	},
	methods: {
		...mapActions(['chageTourStep']),
		toggleFullscreen() {
			this.$root.toggleFullscreen()
		},
		openSection() {
			this.$router.push({
				name: 'section',
				params: { id: 'absorption1' }
			})
			this.$store.commit('CHANGE_STEP', 1)
		}
	}
}
</script>

<style lang="scss">
.active-btn {
	&,
	.v-icon {
		color: #9d1f30 !important;
	}
}
#AnimationPlayerToolbar {
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #ff000000;
	background-image: linear-gradient(180deg, #ff000000 rgba(255, 0, 0, 0) 00);
	border-top: 1px solid #d0d0d000 !important;
	.v-toolbar__content {
		padding: 0;
	}
	.v-btn {
		&.v-btn--icon {
			color: rgba(0, 0, 0, 1);
		}
		.v-icon {
			font-size: 28px;
		}
	}
}
</style>