var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section left-column-wraper d-flex flex-column"},[_c('v-list',{staticClass:"pb-0 pt-0 section-nav"},[_c('v-list-item',{staticClass:"section-nav-link",attrs:{"to":{
				name: 'section',
				params: { id: _vm.location.navmainid }
			},"active-class":"active"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase subtitle-1 font-weight-medium"},[_vm._v(_vm._s(_vm.location.navmaintitle))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"inactive-icon icon_ie"},[_vm._v("mdi-chevron-right-box")]),_c('v-icon',{staticClass:"active-icon icon_ie"},[_vm._v("mdi-chevron-right-box-outline")])],1)],1),_c('v-divider')],1),(_vm.location.subnavigation.length)?_c('v-list',{staticClass:"py-0 section-nav",attrs:{"dense":""}},[_vm._l((_vm.location.subnavigation),function(item,i){return [_c('v-list-item',{key:item.cid,staticClass:"section-nav-link",attrs:{"to":{
					name: 'section',
					params: { id: item[_vm.paramkey] }
				},"active-class":"active"}},[_c('v-list-item-content',{staticClass:"text-uppercase caption"},[_c('div',{staticClass:"d-flex"},[_c('span',{staticStyle:{"padding-right":"5px"}},[_vm._v("•")]),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-list-item-icon',[_c('v-icon',{staticClass:"inactive-icon icon_ie_sm"},[_vm._v("mdi-chevron-right-box")]),_c('v-icon',{staticClass:"active-icon icon_ie_sm"},[_vm._v("mdi-chevron-right-box-outline")])],1)],1),_c('v-divider',{key:i})]})],2):_vm._e(),_c('div',{staticClass:"flex-grow-1 column-panels",attrs:{"id":"section-columns-panel"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-expansion-panels',{attrs:{"multiple":"","accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"caption text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('sectioninfolabel'))+" ")]),_c('v-expansion-panel-content',{staticClass:"body-3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.location.currentnode.cidescription)}})])],1),(_vm.elements.length)?_c('v-expansion-panel',{staticClass:"mt-0",attrs:{"id":"element-table"}},[_c('v-expansion-panel-header',{staticClass:"caption text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('sectionelementslabel'))+" ")]),_c('v-expansion-panel-content',{staticClass:"elements-list"},[_c('v-item-group',{model:{value:(_vm.selectedel),callback:function ($$v) {_vm.selectedel=$$v},expression:"selectedel"}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.elements),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"2"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-img',_vm._g({staticClass:"ma-1 element-thumb",class:active
															? 'element-thumb active'
															: 'element-thumb',staticStyle:{"cursor":"pointer"},attrs:{"src":item.imageSm,"aspect-ratio":"1","alt":item.cititle},on:{"click":toggle},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-1"}})],1)]},proxy:true}],null,true)},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cititle))])])]}}],null,true)})],1)}),1)],1)],1)],1):_vm._e(),(_vm.element)?_c('v-expansion-panel',{staticClass:"mt-0",attrs:{"id":"element-details"}},[_c('v-expansion-panel-header',{staticClass:"caption text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('sectiondesclabel'))+" ")]),_c('v-expansion-panel-content',{staticClass:"body-3"},[_c('div',{staticClass:"font-weight-medium subtitle-1"},[_vm._v(" "+_vm._s(_vm.element.cititle)+" ")]),(_vm.element.imageBg)?_c('v-img',{attrs:{"src":_vm.element.imageBg,"height":"100","aspect-ratio":"1","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-1"}})],1)]},proxy:true}],null,false,1824347719)}):_vm._e(),_c('div',{staticClass:"font-weight-medium",domProps:{"innerHTML":_vm._s(_vm.element.cidescription)}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.element.fc)}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }