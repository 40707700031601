<template>
	<div class="fill-height">
		<LegalNotice v-if="$route.params.id == 'legal-notice'"></LegalNotice>
		<TermsOfUse v-if="$route.params.id == 'terms-of-use'"></TermsOfUse>
		<PrivacyPolicy
			v-if="$route.params.id == 'privacy-policy'"
		></PrivacyPolicy>
		<CookiePolicy v-if="$route.params.id == 'cookie-policy'"></CookiePolicy>
	</div>
</template>

<script>
import LegalNotice from '../components/LegalNotice'
import TermsOfUse from '../components/TermsOfUse'
import PrivacyPolicy from '../components/PrivacyPolicy'
import CookiePolicy from '../components/CookiePolicy'
export default {
	components: {
		LegalNotice,
		TermsOfUse,
		PrivacyPolicy,
		CookiePolicy
	}
}
</script>

<style lang="scss" scoped>
.fill-height {
	max-height: calc(100% - 85px);
}
</style>
