<template>
	<div id="subtitles">
		<v-dialog
			v-if="dialog"
			v-model="dialog"
			max-width="600"
			transition="dialog-bottom-transition"
			hide-overlay
		>
			<v-card>
				<v-card-title class="pa-0">
					<v-toolbar flat dark dense class="v-sheet-bg">
						<v-toolbar-title>{{ location.currentnode.name }}</v-toolbar-title>
						<div
							class="card-text-step"
							v-html="
						location.currentnode.steps[tourcurrentstep - 1]
							.cititle
					"
						></div>
						<v-spacer />
						<v-btn icon dark @click="show_full_subs">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar>
				</v-card-title>
				<v-card-text>
					<div
						class="card-text-"
						v-html="
						location.currentnode.steps[tourcurrentstep - 1]
							.cidescription
					"
					></div>
					<div v-html="location.currentnode.steps[tourcurrentstep - 1].fc"></div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<video playsinline id="videoPlayer" ref="videoPlayer" class="video-js"></video>
		<div
			class="sectionstep-cc"
			v-if="cc_enabled && !toursteptransitioning && $vuetify.breakpoint.lgAndUp && show_subs"
			:class="cc_enabled == 2 ? 'cc-style-subtitle' : 'cc-style-text'"
			:id="cc_enabled == 2 ? '' : 'cc-style-text-id'"
		>
			<div v-if="currentcue && cc_enabled == 2" class="cc-cue" v-html="cuetext"></div>
			<div v-if="cc_enabled == 1" class="cc-cue-full">
				<span
					class="cc-span"
					v-for="(item, ind) in cues"
					:key="ind"
					:class="item.activeclass"
					:id="item.activeclass"
					v-html="item.text"
				></span>
			</div>

			<div v-if="cc_enabled == 1" class="full_subs_div">
				<template>
					<v-btn tile elevation="0" class="full_subs" @click="show_full_subs">
						<v-icon>mdi-plus-thick</v-icon>
					</v-btn>
				</template>
			</div>
		</div>

		<div
			class="sectionstep-cc"
			v-if=" cc_enabled && !toursteptransitioning && $vuetify.breakpoint.mdAndDown && show_subs "
			:class="cc_enabled == 1 ? 'cc-style-subtitle' : 'cc-style-text-m'"
			:id="cc_enabled == 1 ? '' : 'cc-style-text-id'"
		>
			<div v-if="currentcue && cc_enabled == 1" class="cc-cue" v-html="cuetext"></div>
			<div v-if="cc_enabled == 2" class="cc-cue-full">
				<span
					class="cc-span-m"
					v-for="(item, ind) in cues"
					:key="ind"
					:class="item.activeclass"
					:id="item.activeclass"
					v-html="item.text"
				></span>
			</div>

			<div v-if="cc_enabled == 2" class="full_subs_div_m">
				<template>
					<v-btn tile elevation="0" class="full_subs_m" @click="show_full_subs">
						<v-icon>mdi-plus-thick</v-icon>
					</v-btn>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'

export default {
	name: 'AnimationSubtitles',
	props: {
		options: {
			type: Object,
			default() {
				return {
					nativeTextTracks: false
					//autoplay: true
				}
			}
		},
		sources: {
			type: Array,
			default() {
				return []
			}
		},
		stepsprogress: Array
	},
	data() {
		return {
			player: null,
			currentcue: false,
			cuelist: [],
			dialog: false,
			playing_: null,
			show_subs: false,
			tosteppause: false
		}
	},
	computed: {
		...mapState([
			'paramkey',
			'currentlang',
			'tourmode',
			'tourautoplay',
			'tourcurrentstep',
			'toursteptransitioning',
			'tourstepjump',
			'tourstepplaying',
			'tourstepskip',
			'fromURL',
			'step_pause',
			'tourstepended',
			'sound',
			'mainNavigation',
			'cc_enabled',
			'animationplaying'
		]),
		...mapGetters(['location']),
		cuetext() {
			return this.currentcue != false ? this.currentcue : '...'
		},
		cues() {
			let thecues = []
			this.cuelist.forEach(cue => {
				cue.activeclass =
					cue.text == this.currentcue ? 'active-cue' : ''
				thecues.push(cue)
			})
			return thecues
		}
	},
	mounted() {
		let _this = this
		_this.player = videojs(
			_this.$refs.videoPlayer,
			_this.options,
			function onPlayerReady() {
				// console.log('onPlayerReady')
				this.on('ended', () => {
					if (_this.tourcurrentstep != window.iron3d.lastStepIndex) {
						_this.setStepPLayingState(false)
						_this.moveToNextStep()
						_this.$store.commit('TOGGLE_TOUR_STEPENDED', true)
					} else {
						_this.setStepPLayingState(false)
						window.iron3d.playUntilEnd()
					}
				})
				this.on('play', () => {
					_this.setStepPLayingState(true)
					_this.startMuted()
				})
				_this.setsource(_this.tourcurrentstep)
			}
		)
	},
	watch: {
		animationplaying(playing) {
			if (
				playing &&
				!this.step_pause &&
				this.tourstepplaying &&
				this.setStepPLayingState
			) {
				this.player.play()
			}

			if (
				!playing &&
				this.step_pause &&
				this.tourstepplaying &&
				this.setStepPLayingState
			) {
				this.player.pause()
			}
		},
		tourcurrentstep(newStep) {
			this.setsource(newStep)
		},
		toursteptransitioning(newVal) {
			if (!newVal) {
				if (this.tourcurrentstep == 1) {
					this.setsource(1)
					// console.log('set source 3')
				}
				this.player.play()
			}
		},
		sound(notMuted) {
			this.player.muted(!notMuted)
		},
		currentlang(newValue) {
			if (typeof window.iron3d != 'undefined') {
				window.iron3d.setLanguage(newValue)
				if (this.animationplaying) {
					if(!this.fromURL){
					// console.log("from url")
					this.restartPlayer()
					}
				} else {
					this.$store.commit('TOGGLE_ANIMATIONPLAYING', false)
					this.setsource(1)
					this.toStep(0)
				}
			}
		},
		dialog(newVal) {
			if (!newVal) {
				if (this.playing_ == 1) {
					this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
					this.$store.commit('TOGGLE_STEP_PAUSE_', false)
				}
			}
		},
		$route(to, from) {
			//Reset player state if navigating to another view
			if (to.params.id != from.params.id) {
				this.player.pause()
				//this.resetPlayer()
			}
		}
	},
	methods: {
		...mapActions(['chageTourStep']),

		setsource(step) {
			let _this = this
			_this.currentcue = false
			_this.cuelist = []
			//absorption1-step8.mp3
			let audiopath =
				'./audio/' +
				this.currentlang +
				'/' +
				this.location.currentnode[this.paramkey] +
				'-step' +
				step

			// console.log("audio::  "+audiopath)
			this.clearTextTracks()
			this.player.src({
				type: 'audio/mp3',
				src: audiopath + '.mp3'
			})
			let textTrack = this.player.addRemoteTextTrack({
				kind: 'captions',
				language: this.currentlang,
				label: this.currentlang,
				src: audiopath + '.vtt'
			})
			textTrack.track.mode = 'showing'
			textTrack.addEventListener('load', () => {
				for (let i = 0; i < textTrack.track.cues.length; i++) {
					_this.cuelist.push(textTrack.track.cues[i])
				}
				if (
					typeof _this.cuelist !== 'undefined' &&
					_this.cuelist.length > 0
				) {
					_this.show_subs = true
				}
			})
			textTrack.track.addEventListener('cuechange', function() {
				if (textTrack.track.activeCues != null) {
					const cue = textTrack.track.activeCues[0]
					if (cue !== undefined) {
						_this.currentcue = textTrack.track.activeCues[0].text
					} else {
						_this.currentcue = false
					}
				} else {
					_this.currentcue = false
				}

				var id = document.getElementById('active-cue')
				var ua = window.navigator.userAgent
				var isIE = /MSIE|Trident/.test(ua)

				if (!isIE) {
					if (id != null && _this.$vuetify.breakpoint.lgAndUp) {
						id.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						})
					} else if (
						id != null &&
						_this.$vuetify.breakpoint.mdAndDown
					) {
						id.scrollIntoView({
							behavior: 'smooth',
							block: 'start'
						})
					} else {
						//nothing
					}
				} else {
					//not ie - options not supported.
					if (id != null && _this.$vuetify.breakpoint.lgAndUp) {
						id.scrollIntoViewIfNeeded({})
					} else if (
						id != null &&
						_this.$vuetify.breakpoint.mdAndDown
					) {
						id.scrollIntoViewIfNeeded({})
					} else {
						//nothing
					}
				}
			})
			if (!this.toursteptransitioning && this.animationplaying) {
				this.player.play()
			}
		},
		clearTextTracks() {
			let tracks = this.player.remoteTextTracks()
			for (var i = tracks.length - 1; i >= 0; i--) {
				this.player.removeRemoteTextTrack(tracks[i])
			}
		},
		resetPlayer() {
			this.player.pause()
			this.currentcue = false
			this.cuelist = []
			this.clearTextTracks()
			this.setStepPLayingState(false)
		},
		restartPlayer() {
			this.setsource(1)
			this.toStep(0)
			this.$store.commit('TOGGLE_ANIMATIONLOADING', false)
		},
		setStepPLayingState(state) {
			this.$store.commit('TOGGLE_TOUR_STEPPLAYING', state)
		},
		moveToNextStep() {
			if (this.animationplaying) {
				let tourpayload = {
					direction: 'next',
					jump: false
				}
				this.chageTourStep(tourpayload)
			}
			// console.log('move to next step ()')
			this.$store.commit('TOGGLE_TOUR_STEPENDED', false)
		},
		startMuted() {
			if (this.sound) {
				this.player.muted(false)
			} else {
				this.player.muted(true)
			}
			//when step reached button = pause
			// this.$store.commit('TOGGLE_ANIMATIONPLAYING')
		},
		toStep(step) {
			let tourpayload = {
				direction: 'skip',
				jump: true,
				step: step + 1
			}
			this.chageTourStep(tourpayload)
			this.$store.commit('TOGGLE_TOUR_AUTOPLAY', false)
			// console.log('toStep')
		},
		show_full_subs() {
			if (this.animationplaying && this.playing_ == null) {
				this.playing_ = 1
			}

			if (!this.animationplaying && this.playing_ == null) {
				this.playing_ = 2
			}

			if (this.animationplaying && !this.dialog) {
				this.dialog = true
				this.$store.commit('TOGGLE_ANIMATIONPLAYING', false)
				this.$store.commit('TOGGLE_STEP_PAUSE_', true)
				this.playing_ = 1
			} else if (
				!this.animationplaying &&
				this.dialog &&
				this.playing_ == 1
			) {
				this.dialog = false
				this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
				this.$store.commit('TOGGLE_STEP_PAUSE_', false)
			} else if (!this.animationplaying && !this.dialog) {
				this.dialog = true
				this.playing_ = 2
			} else if (
				!this.animationplaying &&
				this.dialog &&
				this.playing_ == 2
			) {
				this.dialog = false
			}
		}
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose()
		}
	}
}
</script>

<style lang="scss">
//@import 'video.js/dist/video-js.css';
#subtitles {
	position: absolute;
	z-index: 4;
	bottom: 50px;
	left: 0;
	width: 100%;
	.video-js {
		position: absolute;
		bottom: 300%;
		// width: 700px;
		// height: 500px;
		margin-bottom: 100px;
		left: -1000px;
	}
	.sectionstep-cc {
		position: absolute;
		z-index: 50;
		// display: flex;
		bottom: 0;
		width: 100%;
		padding: 15px 20px 15px;
		text-align: center;
		color: #ffffff;
		&.cc-style-subtitle {
			.cc-cue {
				display: inline;
				padding: 3px 5px;
				background-color: rgba(0, 0, 0, 0.7);
			}
		}
	}

	.cc-style-text {
		overflow-y: auto;
		max-height: 67px;
		display: flex;
		//margin-bottom:5px;
		background-color: rgba(0, 0, 0, 0.6);
		.active-cue {
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	.cc-style-text-m {
		overflow-y: auto;
		max-height: 75px;
		// width: 80%;
		//margin-bottom:5px;
		background-color: rgba(0, 0, 0, 0.6);
		#active-cue {
			background-color: rgba(0, 0, 0, 0.8);
		}
	}

	.cc-span {
		padding-left: 2.5px;
		padding-right: 2.5px;
	}
	.cc-span-m {
		padding-left: 2.5px;
		padding-right: 2.5px;
	}

	.full_subs_div {
		display: flex;
		position: absolute;
		// right: 30px;
		// bottom: 6%;
		right: 20px;
		position: fixed;
	}

	.full_subs {
		min-width: 0px !important;
		padding: 6px;
		background-color: #ffffff00;
		color: #ffffff;
	}

	.full_subs_div_m {
		display: flex;
		position: absolute;
		// right: 30px;
		// bottom: 6%;
		right: 0px;
		bottom: 70px;
		position: fixed;
	}

	.full_subs_m {
		min-width: 0px !important;
		padding: 6px;
		background-color: #ffffff00;
		color: #ffffff;
	}

	.cc-cue-full {
		width: 95%;
	}
}

.card-text- {
	padding-top: 20px !important;
}

.card-text-step {
	padding-left: 10px !important;
	font-size: 0.9rem;
	font-weight: 300;
}

.v-dialog .v-dialog--active {
	overflow-x: hidden;
}
</style>
