import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import fullscreen from 'vue-fullscreen'
import i18n from './i18n'
import { sync } from 'vuex-router-sync'
import scrshot from 'js_screen_shots'
import { mapState } from 'vuex'
import '@/assets/global.scss'
//import VueGtag from "vue-gtag";
import VueGtm from 'vue-gtm'
import { VueHammer } from 'vue2-hammer'
import visibility from 'vue-visibility-change'

Vue.use(fullscreen)
/* Vue.use(VueGtag, {
	config: { id: "G-V3XEBMLH2K" }
}); */
Vue.use(VueGtm, {
	id: 'GTM-N894RJ2',
	loadScript: false
	//debug: true
	//vueRouter: router
})
Vue.use(VueHammer)
Vue.use(visibility)

window.html2canvas = scrshot.html2canvas
window.Canvas2Image = scrshot.Canvas2Image

Vue.config.productionTip = false
const unsync = sync(store, router)
const requireComponent = require.context(
	'./components',
	false,
	/Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
	const componentConfig = requireComponent(fileName)

	const componentName = upperFirst(
		camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
	)

	Vue.component(componentName, componentConfig.default || componentConfig)
})

const VM = new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
	computed: {
		...mapState([
			'savescr',
			'sound',
			'sound_enabled',
			'sound_man_check_en',
			'sound_man_check_fr',
			'sound_man_check_de',
			'animationplaying',
			'tourstepplaying',
			'currentlang'
		])
	},
	data: () => ({
		isMobile: false,
		orig_not_muted: true,
		orig_playing: true,
		ts_playing: false
	}),
	watch: {
		savescr(newValue) {
			let _this = this
			if (newValue == 2) {
				//let _this = this
				let target = document.getElementsByTagName('body')[0]
				let footerreferences = target.querySelector(
					'#footer-references'
				)
				window
					.html2canvas(target, {
						useCORS: true,
						foreignObjectRenderign: true,
						allowTaint: false,
						height:
							window.innerHeight +
							footerreferences.offsetHeight +
							20,
						onclone(dom) {
							dom.querySelector(
								'#copyright-watermark'
							).style.display = 'block'
							let animationcontrols = dom.querySelector(
								'#AnimationPlayerToolbar'
							)
							// console.log("anim controls:: "+animationcontrols)
							animationcontrols.parentNode.removeChild(
								animationcontrols
							)
							let subs = dom.querySelector('#subtitles')
							if (subs != null) {
								subs.parentNode.removeChild(subs)
							}
							let scroverlay = dom.querySelector(
								'#screenshot-overlay'
							)
							scroverlay.parentNode.removeChild(scroverlay)

							// let elem_table = dom.querySelector('#element-table')
							// if (elem_table != null) {
							// 	elem_table.parentNode.removeChild(elem_table)
							// }
						}
					})
					.then(function(canvas) {
						//canvas.classList = 'scrcanvas'
						canvas.setAttribute('class', 'scrcanvas')
						target.insertAdjacentElement('beforeend', canvas)
						let type = 'png'
						let w = canvas.width
						let h = canvas.height + 50
						let date = new Date()
						let f =
							'Screenshot_' +
							date.getFullYear() +
							'-' +
							(date.getMonth() + 1) +
							'-' +
							date.getDate() +
							'_' +
							date.getHours() +
							'-' +
							date.getMinutes() +
							'-' +
							date.getSeconds()
						if (canvas.msToBlob) {
							// IE
							try {
								let blob = canvas.msToBlob()
								window.navigator.msSaveBlob(blob, f + '.png')
								canvas.parentNode.removeChild(canvas)
							} catch (error) {
								canvas.parentNode.removeChild(canvas)
							}
						} else {
							// CH, FF
							window.Canvas2Image.saveAsImage(
								canvas,
								w,
								h,
								type,
								f
							)
							canvas.parentNode.removeChild(canvas)
						}
						//canvas.parentNode.removeChild(canvas)
						_this.$store.commit('SET_SCR', 0)
					})
			}
		},
		sound(notMuted) {
			this.orig_not_muted = notMuted
		},
		currentlang(newValue) {
			if (newValue == 'tr') {
				if (this.sound_man_check_tr == false) {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', false)
				} else {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', true)
				}
			}
			if (newValue == 'fr') {
				if (this.sound_man_check_fr == false) {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', false)
				} else {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', true)
				}
			}
			if (newValue == 'de') {
				if (this.sound_man_check_de == false) {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', false)
				} else {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', true)
				}
			}
			if (newValue == 'en') {
				if (this.sound_man_check_en == false) {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', false)
				} else {
					this.$store.commit('TOGGLE_SOUND_ENABLED_ON', true)
				}
			}
		},
		animationplaying(playing) {
			this.orig_playing = playing
		},
		tourstepplaying(newValue) {
			this.ts_playing = newValue
		}
	},
	methods: {
		toggleFullscreen() {
			this.$fullscreen.toggle(this.$el, {
				wrap: false,
				callback: this.fullscreenChange
			})
		},
		fullscreenChange(fullscreen) {
			//console.log(fullscreen);
			this.$store.dispatch('toggleFullScreen', fullscreen)
		},
		pauseWhenHidden() {
			this.$store.commit('TOGGLE_ANIMATIONPLAYING', false)
			this.$store.commit('TOGGLE_STEP_PAUSE_', true)
		},
		playWhenShown() {
			this.$store.commit('TOGGLE_ANIMATIONPLAYING', true)
			this.$store.commit('TOGGLE_STEP_PAUSE_', false)
		}
	},
	created() {
		if (this.$vuetify.breakpoint.mdAndDown) {
			// console.log("aaaa md and down!!"  + screen_size)
			this.isMobile = true
		}
	},
	mounted() {
		/* eslint-disable */
		let self = this
		const handler = visibility.change((evt, hidden) => {
			if (this.isMobile && window.iron3d != undefined) {

				if (hidden && self.orig_not_muted) {
					window.iron3d.setMute(true)
				}
				if (!hidden && self.orig_not_muted) {
					window.iron3d.setMute(false)
				}
				if (hidden && !self.orig_not_muted) {
					// do nothing
				}

				//pauses player if step not playing -- 
				if (hidden && self.orig_playing) {
					window.iron3d.pause()
				}
				if (!hidden && self.orig_playing) {
					window.iron3d.play()
				}
				if (hidden && !self.orig_not_muted) {
					// do nothing
				}

				//pause player if step playing --
				if (hidden && self.orig_playing && self.ts_playing) {
					self.pauseWhenHidden()
				}
				if (!hidden && self.orig_playing && self.ts_playing) {
					self.playWhenShown()
				}
				if (hidden && !self.orig_not_muted && !self.ts_playing) {
					// do nothing
				}


			}
		});
		/* eslint-disable */
	},
	destroyed() {
		unsync()
	}
})

store.$app = VM
VM.$mount('#app')

