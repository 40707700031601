var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{staticClass:"footer text-center list-center",attrs:{"padless":""}},[_c('div',{staticClass:"pb-0 secondary-nav-footer"},[_vm._l((_vm.secondaryNavigation.childs),function(item){return [_c('router-link',{key:item.cid,staticClass:"subtitle-1 font-weight-regular px-2 text-nowrap footer-link",attrs:{"to":{
					name: item.route,
					params: { id: item[_vm.paramkey] }
				}}},[_vm._v(_vm._s(item.name)+" ")])]}),_c('a',{staticClass:"subtitle-1 font-weight-regular px-2 text-nowrap footer-link",attrs:{"href":"https://privacy.csl.com/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('privacy_policy_app'))+" ")]),_c('router-link',{staticClass:"subtitle-1 font-weight-regular px-2 text-nowrap footer-link",attrs:{"to":{
				name: 'secondaryNavigation',
				params: { id: 'cookie-policy' }
			}}},[_vm._v(" "+_vm._s(_vm.$t('cookie_policy_app'))+" ")]),_c('a',{staticClass:"subtitle-1 font-weight-regular px-2 text-nowrap footer-link",on:{"click":_vm.toggleCookieSettings}},[_vm._v(" "+_vm._s(_vm.$t('cookie_setting_app'))+" ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }