var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height",class:{ 'text-full': _vm.showFullText, 'text-half': !_vm.showFullText }},[_c('div',{staticClass:"cc"}),_c('v-container',{staticClass:"animation-section fill-height",class:{
			'text-full': _vm.showFullText,
			'text-half': !_vm.showFullText
		}},[(_vm.dataloaded)?_c('div',{staticClass:"home d-flex flex-column",class:{
				'left-column-wraper-full': _vm.showFullText,
				'left-column-wraper-half': !_vm.showFullText
			}},[_c('div',[_c('v-card',{staticClass:"pa-2 home-card",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(_vm.navigation.cititle)+" ")]),_c('div',{staticClass:"t"},[(!_vm.showFullText)?_c('v-card-text',{staticClass:"home-text-half",domProps:{"innerHTML":_vm._s(_vm.navigation.fc)}}):_vm._e(),(!_vm.showFullText)?_c('v-icon',{staticClass:"rm",on:{"click":_vm.showText}},[_vm._v("mdi-chevron-down")]):_vm._e(),(_vm.showFullText)?_c('v-card-text',{staticClass:"home-text-full",domProps:{"innerHTML":_vm._s(_vm.navigation.fc)}}):_vm._e(),(_vm.showFullText)?_c('v-icon',{staticClass:"rm",on:{"click":_vm.hideText}},[_vm._v("mdi-chevron-up")]):_vm._e()],1),_c('v-card-actions',[_c('div',{staticClass:"btns"},[_c('div',{staticClass:"btns_start"},[_c('v-btn',{staticClass:"start-app-btn",attrs:{"depressed":"","dark":"","color":"rgb(0, 102, 255)"},on:{"click":_vm.go_to_iM}},[_vm._v(_vm._s(_vm.$t('start_app')))])],1),_c('div',{staticClass:"btns_download"},[(_vm.windows)?_c('v-btn',{staticClass:"install-app-btn",style:({ display: _vm.installBtn }),attrs:{"outlined":"","color":"rgb(0, 102, 255)"},on:{"click":_vm.install_btn_tracker}},[_vm._v(_vm._s(_vm.$t('install_app')))]):_vm._e()],1)])])],1),_c('v-list',{staticClass:"pb-0 resnews flex-grow-1"},[_vm._l((_vm.micrositeNavigation.childs),function(item,ind){return [_c('v-list-item',{key:item.cid,staticClass:"section-nav-link",attrs:{"to":{
								name: item.route,
								params: { id: item[_vm.paramkey] }
							},"active-class":"active"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase subtitle-1 font-weight-medium"},[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider',{key:ind})]})],2)],1),(_vm.$vuetify.breakpoint.mdAndDown)?_c('HomeFooter',{staticClass:"footer"}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"bg"})]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('HomeFooter'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }