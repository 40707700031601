<template>
	<v-snackbar
		class="popup"
		v-if="showpopup && updatePopup && $vuetify.breakpoint.lgAndUp"
		v-model="popup"
		:timeout="timeout"
		:multi-line="multiLine"
		dark
	>
		There is a newer version available!
		<div class="popup-buttons">
			<v-btn color="white" class="update-btn" text @click="reload">Update</v-btn>
			<v-btn color="#9d1f30" class="close-btn" text @click="cancel">Close</v-btn>
		</div>
	</v-snackbar>
	<v-snackbar
		class="popup"
		v-else-if="showpopup && updatePopup && $vuetify.breakpoint.mdAndDown"
		v-model="popup"
		:timeout="timeout"
		:multi-line="multiLine"
		dark
		vertical
	>
		There is a newer version available!
		<div class="popup-buttons">
			<v-btn color="white" class="update-btn-v" text @click="reload">Update</v-btn>
			<v-btn color="#9d1f30" class="close-btn" text @click="cancel">Close</v-btn>
		</div>
	</v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
import event from '../eventbus'
export default {
	name: 'SWUpdatePopup',
	data: () => ({
		popup: true,
		multiLine: true,
		timeout: 0,
		updateEvent: null,
		message: '',
		showpopup: false
	}),
		computed: {
		...mapState(['updatePopup'])},
	created() {
		//event.$on('sw-ready', this.onSWUpdated)
		//event.$on('sw-cached', this.onSWUpdated)
		//event.$on('sw-updatefound', this.onSWUpdated)
		event.$on('sw-updated', this.onSWUpdated)
		// console.log("aaa "+this.updatePopup)
	},
	methods: {
		onSWUpdated(e) {
			this.showpopup = true
			this.updateEvent = e
			// console.log('onSwUpdated Called')
		},
		cancel() {
			this.showpopup = false
		},
		reload() {
			window.location.reload(true)
		}
	}
}
</script>

<style>
.v-snack__wrapper {
	background-color: #cfd1e6 !important;
	color: black;
	width: 50%;
}
.update-btn {
	background-color: rgb(0, 102, 255);
	margin-right: -20px !important;
	/* margin-top: 20px !important;
	margin-bottom: 10px !important; */
}

.update-btn-v {
	background-color: rgb(0, 102, 255);
	/* margin-right: -20px !important; */
	/* margin-top: 20px !important;
	margin-bottom: 10px !important; */
}

.popup-buttons {
	display: flex;
	justify-content: flex-end;
}

/* .close-btn{
margin-top: 20px;
margin-bottom: 10px !important;
} */
</style>
