<template>
	<v-footer padless class="footer text-center list-center">
		<div class="pb-0 secondary-nav-footer">
			<template v-for="item in secondaryNavigation.childs">
				<router-link
					:key="item.cid"
					:to="{
						name: item.route,
						params: { id: item[paramkey] }
					}"
					class="subtitle-1 font-weight-regular px-2 text-nowrap footer-link"
					>{{ item.name }}
				</router-link>
			</template>
			<a
				href="https://privacy.csl.com/"
				class="subtitle-1 font-weight-regular px-2 text-nowrap footer-link"
				target="_blank"
			>
				{{ $t('privacy_policy_app') }}
			</a>

			<router-link
				:to="{
					name: 'secondaryNavigation',
					params: { id: 'cookie-policy' }
				}"
				class="subtitle-1 font-weight-regular px-2 text-nowrap footer-link"
			>
				{{ $t('cookie_policy_app') }}
			</router-link>
			<a
				@click="toggleCookieSettings"
				class="subtitle-1 font-weight-regular px-2 text-nowrap footer-link"
			>
				{{ $t('cookie_setting_app') }}
			</a>
		</div>
	</v-footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
	name: 'Home',
	components: {},

	data: () => ({
		cookiepolicybtnlabel: 'Cookie Policy',
		cookiesettingbtnlabel: 'Cookie Settings'
	}),
	computed: {
		...mapState([
			'mainNavigation',
			'micrositeNavigation',
			'paramkey',
			'secondaryNavigation'
		])
	},
	created() {
		if (window.OneTrust) {
			this.cookiesettingbtnlabel = window.OneTrust.GetDomainData().CookieSettingButtonText
		}
	},
	methods: {
		toggleCookieSettings() {
			if (window.OneTrust) {
				window.OneTrust.ToggleInfoDisplay()
			}
		}
	}
}
</script>

<style scoped>
.footer {
	background-color: #c0c2e1 !important;
	bottom: 0px;
	/* padding-top: 100px; */
}
@media only screen and (max-width: 1263px) {
	.footer {
		padding-bottom: 30px;
		margin-top: 30px;
	}
}
.footer-link {
	display: inline-block;
	text-decoration: none;
	color: rgba(0, 0, 0, 0.87);
}
.secondary-nav-footer {
	/* display: flex;
	flex-direction: row; */
	margin-bottom: 0px;
	/* background-color: #c0c2e1 !important; */
	/* width: 20%; */
}

.theme--light.v-sheet {
	background-color: #bbbdde !important;
}

@media only screen and (max-width: 1263px) {
	.theme--light.v-sheet {
		background-color: #bbbdde00 !important;
	}
}

.list-center {
	display: flex;
	justify-content: center;
}

.v-list-item--link:before {
	background-color: #ffffff00 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
	opacity: 0;
}

.v-list-item__title:hover,
.v-list-item__subtitle:hover {
	color: darkblue;
	text-decoration: underline;
}
</style>
