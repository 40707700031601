<template>
	<v-breadcrumbs light :items="location.pathlist" class="pl-0 flex-nowrap">
		<template v-slot:item="props">
			<v-breadcrumbs-item
				v-if="
					isIMLanding ||
						(isSection && $vuetify.breakpoint.lgAndUp) ||
						props.item.ishome ||
						props.item.current
				"
				:to="{
					name: props.item.route,
					params: { id: props.item[paramkey] }
				}"
				:active-class="
					props.item.current ? 'v-breadcrumbs__item--disabled' : ''
				"
				:class="!$vuetify.breakpoint.lgAndUp ? 'br_mobile' : ''"
			>
				<template v-if="!props.item.ishome">
					{{ props.item.name }}
				</template>
			</v-breadcrumbs-item>
			<v-breadcrumbs-item
				v-if="isMicrosite || isSecondaryNavigation || props.item.ishome"
				:to="{
					name: props.item.route,
					params: { id: props.item[paramkey] }
				}"
				:active-class="
					props.item.current ? 'v-breadcrumbs__item--disabled' : ''
				"
				:class="!$vuetify.breakpoint.lgAndUp ? 'px-0' : ''"
			>
				<v-icon v-if="props.item.ishome" class="mr-1">mdi-home</v-icon>
				<template
					v-if="props.item.ishome && $vuetify.breakpoint.lgAndUp"
				>
					<span>{{ $t('appname') }}</span>
				</template>
				<template v-if="!props.item.ishome">
					{{ props.item.name }}
				</template>
			</v-breadcrumbs-item>

			<v-menu
				light
				bottom
				v-if="
					($vuetify.breakpoint.lgAndUp || props.item.ishome) &&
						props.item.childs &&
						props.item.childs.length &&
						!isMicrosite &&
						!isSecondaryNavigation
				"
			>
				<template v-slot:activator="{ on: breadcrumb }">
					<v-btn icon v-on="{ ...breadcrumb }">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list class="nav-dropdown">
					<template v-for="(child, ind) in props.item.childs">
						<v-list-item
							:key="ind"
							:to="{
								name: child.route,
								params: { id: child[paramkey] }
							}"
						>
							<v-list-item-title>
								{{ child.name }}
							</v-list-item-title>
						</v-list-item>
						<template v-if="$vuetify.breakpoint.mdAndDown">
							<v-list-item
								v-for="(subchild, subind) in child.childs"
								:key="ind + '-' + subind"
								:to="{
									name: 'section',
									params: { id: subchild[paramkey] }
								}"
							>
								<v-list-item-title>
									<v-icon>mdi-chevron-right</v-icon>
									{{ subchild.name }}
								</v-list-item-title>
							</v-list-item>
						</template>
					</template>
				</v-list>
			</v-menu>

			<v-menu
				light
				bottom
				v-if="
					(isMicrosite || isSecondaryNavigation) &&
						props.item.ishome &&
						props.item.childs.length
				"
			>
				<template v-slot:activator="{ on: breadcrumb }">
					<v-btn icon v-on="{ ...breadcrumb }">
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list class="nav-dropdown">
					<template v-for="(child, ind) in props.item.childs">
						<v-list-item
							:key="ind"
							:to="{
								name: child.route,
								params: { id: child[paramkey] }
							}"
						>
							<v-list-item-title>
								{{ child.name }}
							</v-list-item-title>
						</v-list-item>
						<template>
							<v-list-item
								v-for="(subchild, subind) in child.childs"
								:key="ind + '-' + subind"
								:to="{
									name: 'section',
									params: { id: subchild[paramkey] }
								}"
							>
								<v-list-item-title>
									<v-icon>mdi-chevron-right</v-icon>
									{{ subchild.name }}
								</v-list-item-title>
							</v-list-item>
						</template>
					</template>
				</v-list>
			</v-menu>
		</template>
		<template class="d-none" v-slot:divider>
			<v-icon></v-icon>
		</template>
	</v-breadcrumbs>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
export default {
	name: 'Breadcrumb',
	computed: {
		...mapState(['navigation', 'paramkey', 'dataloaded', 'tourmode']),
		...mapGetters([
			'currentlangobj',
			'location',
			'homeState',
			'isMicrosite',
			'isIMLanding',
			'isSection',
			'homeState',
			'isSecondaryNavigation'
		])
	}
}
</script>

<style>
.br_mobile {
	padding-left: 0px !important;
	padding-right: 0px !important;
	max-width: 60vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* .v-breadcrumbs{
	padding-left: 0% !important;
	padding-right: 0% !important;
} */

.mobile-view #app-header .v-breadcrumbs {
	max-width: 100vw !important;
}
</style>
