var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-column-wraper d-flex flex-column"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-list-item',{staticClass:"section-nav-link",attrs:{"active-class":"active"},on:{"click":function($event){return _vm.$router.push({ name: 'ironmetabolism' })}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-play-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-uppercase subtitle-1 font-weight-medium"},[_vm._v(_vm._s(_vm.mainNavigation.name))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right-box-outline")])],1)],1)],1),_c('v-divider'),_c('v-list',{staticClass:"pb-0 pt-0"},[_vm._l((_vm.micrositeNavigation.childs),function(item,ind){return [_c('v-list-item',{key:item.cid,staticClass:"section-nav-link",attrs:{"to":{
						name: item.route,
						params: { id: item[_vm.paramkey] }
					},"active-class":"active"},on:{"click":function($event){return _vm.closeDrawer()}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-newspaper")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider',{key:ind})]})],2)],1),_c('v-list',{staticClass:"pb-0 pt-0"},[_c('v-divider'),_vm._l((_vm.secondaryNavigation.childs),function(item,ind){return [_c('v-list-item',{key:item.cid,staticClass:"section-nav-link",attrs:{"to":{
					name: item.route,
					params: { id: item[_vm.paramkey] }
				},"active-class":"active"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(_vm._s(item.name))])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider',{key:ind})]}),_c('v-list-item',{staticClass:"section-nav-link",attrs:{"active-class":"active","href":"https://privacy.csl.com/","target":"_blank"}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('privacy_policy_app'))+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-divider',{key:_vm.ind}),_c('v-list-item',{staticClass:"section-nav-link",attrs:{"active-class":"active","to":{
				name: 'secondaryNavigation',
				params: { id: 'cookie-policy' }
			}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('cookie_policy_app'))+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-chevron-right")])],1)],1),_c('v-list-item',{staticClass:"section-nav-link",attrs:{"active-class":"active"},on:{"click":_vm.toggleCookieSettings}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t('cookie_setting_app'))+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"icon_ie"},[_vm._v("mdi-open-in-new")])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }